import { keyBy } from '../../assets/js/utils';
import { getText, appendText } from '../../assets/js/text-renderer';
import '../shared/sectores';
import '../shared/news-card';
import '../../assets/js/scroller';
import 'bootstrap/js/dist/carousel';
import './home.scss';
import 'swiffy-slider/css';
import './news-slider.scss';

import { swiffyslider } from 'swiffy-slider';
window.swiffyslider = swiffyslider;

window.addEventListener('load', () => {
  window.swiffyslider.init();
});

document.addEventListener('DOMContentLoaded', function () {
  if (DEVELOPMENT || (window['__PRERENDER_INJECTED'] && window['__PRERENDER_INJECTED'].render_text)) {
    loadText();
  }
});

function loadText() {
  getText('keywords').then(function (dict) {
    const texts = dict.stories[0].content;
    appendText(texts.servicios, 'home-servicios');
    appendText(texts.estrategia_analitica, 'home-estrategia-analitica');
    appendText(texts.preparacion_datos, 'home-preparacion-datos');
    appendText(texts.infraestructura_analitica, 'home-infraestructura-analitica');
    appendText(texts.business_intelligence, 'home-business-intelligence');
    appendText(texts.noticias, 'news-title');
    appendText(texts.noticias_hablan, 'news-subtitle');

    window.dispatchEvent(new CustomEvent('rendered-text', { detail: 'keywords' }));
  });

  getText('cta').then(function (dict) {
    const texts = keyBy(dict.stories, 'slug');
    appendText(texts['cta'].content.title, 'cta-title');
    appendText(texts['cta'].content.description, 'cta-description');
    appendText(texts['cta'].content.button, 'cta-button');

    window.dispatchEvent(new CustomEvent('rendered-text', { detail: 'cta' }));
  });

  getText('home')
    .then(function (dict) {
      const texts = keyBy(dict.stories, 'slug');
      console.log(texts);

      appendText(texts.header.content.title, 'header-title');
      appendText(texts.header.content.description, 'header-description');
      appendText(texts.header.content.button, 'header-button');
      appendText(texts.intro.content.title, 'intro-title');
      appendText(texts.intro.content.description, 'intro-text');

      appendText(texts['areas-conocimiento-1'].content.title, 'section-1-1-title');
      appendText(texts['areas-conocimiento-1'].content.text, 'section-1-1-text');
      appendText(texts['areas-conocimiento-1'].content.items[0].name, 'section-1-1-items-0');
      appendText(texts['areas-conocimiento-1'].content.items[1].name, 'section-1-1-items-1');
      appendText(texts['areas-conocimiento-1'].content.button, 'section-1-1-button');

      appendText(texts['areas-conocimiento-2'].content.title, 'section-1-2-title');
      appendText(texts['areas-conocimiento-2'].content.text, 'section-1-2-text');
      appendText(texts['areas-conocimiento-2'].content.items[0].name, 'section-1-2-items-0');
      appendText(texts['areas-conocimiento-2'].content.items[1].name, 'section-1-2-items-1');
      appendText(texts['areas-conocimiento-2'].content.button, 'section-1-2-button');

      appendText(texts['areas-conocimiento-3'].content.title, 'section-1-3-title');
      appendText(texts['areas-conocimiento-3'].content.text, 'section-1-3-text');
      appendText(texts['areas-conocimiento-3'].content.items[0].name, 'section-1-3-items-0');
      appendText(texts['areas-conocimiento-3'].content.items[1].name, 'section-1-3-items-1');
      appendText(texts['areas-conocimiento-3'].content.items[2].name, 'section-1-3-items-2');
      appendText(texts['areas-conocimiento-3'].content.button, 'section-1-3-button');

      appendText(texts['areas-conocimiento-4'].content.title, 'section-1-4-title');
      appendText(texts['areas-conocimiento-4'].content.text, 'section-1-4-text');
      appendText(texts['areas-conocimiento-4'].content.items[0].name, 'section-1-4-items-0');
      appendText(texts['areas-conocimiento-4'].content.items[1].name, 'section-1-4-items-1');
      appendText(texts['areas-conocimiento-4'].content.button, 'section-1-4-button');

      appendText(texts['ai-section'].content.title, 'ai-pretitle');
      appendText(texts['ai-section'].content.text, 'ai-section');
      appendText(texts['ai-section'].content.link, 'ai-button');

      appendText(texts.platform.content.title, 'platform-title');
      appendText(texts.platform.content.subtitle, 'platform-subtitle');
      appendText(texts.platform.content.text, 'platform-text');

      appendText(texts['smart-bi'].content.title, 'smartbi-title');
      appendText(texts['smart-bi'].content.description, 'smartbi-text');
      appendText(texts['smart-bi'].content.button, 'smartbi-button');

      appendText(texts.empresas.content.text, 'empresas-text');

      window.dispatchEvent(new CustomEvent('rendered-text', { detail: 'content' }));
    })

    .catch(function (err) {
      console.log('Fetch Error :-S', err);
    });
}
