const template = (title, date, href, src) => `
  <a
    href="${href}"
    target="_blank"
  >
    <img loading="lazy" src="${src}" alt="" />
    <h4 class="lh-sm">${title}</h4>
    <span>${date}</span>

  </a>
`;

class News extends HTMLElement {
  connectedCallback() {
    const title = this.getAttribute('data-title');
    const date = this.getAttribute('data-date');
    const href = this.getAttribute('data-href');
    const src = this.getAttribute('data-src');

    this.innerHTML = template(title, date, href, src);
  }
}

customElements.define('custom-news', News);
