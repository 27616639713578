let triggers = document.querySelectorAll('.home-areas-contents li');

function toggleActiveClass(element) {
  triggers.forEach((trigger) => trigger.classList.remove('active'));
  element.classList.add('active');
}

function handleIntersect(entries, observer) {
  if (!entries[0].isIntersecting || entries[0].intersectionRatio !== 1) return;

  const activeLink = document.querySelector('#' + entries[0].target.previousElementSibling.id + '-trigger');
  if (!activeLink) return;

  toggleActiveClass(activeLink);
}

triggers.forEach((el) =>
  el.addEventListener('click', (ev) => {
    const activeLink = document.querySelector(ev.srcElement.hash + '-trigger');
    if (!activeLink) return;
    setTimeout(() => toggleActiveClass(activeLink), 100);
  })
);

window.addEventListener('load', () => {
  const options = {
    root: null,
    rootMargin: '0px',
    threshold: 1,
  };

  const observableSections = document.querySelectorAll('.home-areas-section');
  let observer = new IntersectionObserver(handleIntersect, options);
  observableSections.forEach((section) => observer.observe(section));
});
