const ENG = true;
const apiUrl = 'https://api.storyblok.com/v1/cdn/stories'; //+ (ENG ? '/en' : '');
const versionUrl = 'https://api.storyblok.com/v1/cdn/spaces/me?token=AgRW0tvwZDNSm0qtFhskEQtt';
const apiToken = 'AgRW0tvwZDNSm0qtFhskEQtt';
import 'regenerator-runtime/runtime.js';
import RichTextResolver from 'storyblok-js-client/dist/rich-text-resolver.es';
const resolver = new RichTextResolver();

const buildLanguage = window['__PRERENDER_INJECTED'] && window['__PRERENDER_INJECTED'].language;
const urlLanguage = window.location.pathname.indexOf('/en/') > -1 ? 'en' : 'es';
const languagePrefix = buildLanguage ?? urlLanguage;

export async function getText(folder) {
  let response = await fetch(versionUrl);
  let versionData = await response.json();
  const version = versionData.space.version;
  const contentUrl =
    apiUrl +
    `?starts_with=${languagePrefix === 'en' ? 'en/' : ''}${folder}&cv=${version}&version=published&token=${apiToken}`;

  return await fetch(contentUrl).then(function (response) {
    if (response.status !== 200) {
      console.log('Looks like there was a problem. Status Code: ' + response.status);
      return;
    }
    return response.json();
  });
}

export function appendText(text, id, element = document) {
  if (!text) return;
  const htmlEl = element.querySelector(`#${id}`);
  if (!htmlEl) return;
  if (typeof text === 'object' && text.type === 'doc') {
    const html = resolver.render(text);
    const htmlNode = document.createRange().createContextualFragment(html.trim());
    htmlEl.appendChild(htmlNode);
  } else {
    const textNode = document.createTextNode(text);
    htmlEl.appendChild(textNode);
  }
}
