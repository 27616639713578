const template = (title, date, href, src) => `
  <a
    href="${href}"
    target="_blank"
  >
    <div>
      <img 
        loading="lazy" 
        src="${src}" 
        alt="News Image Logo" 
      />
      <h5 class="lh-sm">${title}</h5>
    </div>
    <span>${date}</span>
  </a>
`;

class News extends HTMLElement {
  connectedCallback() {
    const title = this.getAttribute('data-title');
    const date = this.getAttribute('data-date');
    const href = this.getAttribute('data-href');
    const src = this.getAttribute('data-src');

    this.innerHTML = template(title, date, href, src);
  }
}

customElements.define('custom-news', News);
